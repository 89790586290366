import react from 'react'

function Logout(){
    
    return(
        <>
        </>
    )
}

export default Logout;